import { createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet } from 'react-router-dom'
import { Col, Image, Row } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import clsx from 'classnames'

import Logo from '@/assets/images/logo.svg'
import { FlagType } from '@/components/Flag'
import { LanguageSwitcher } from '@/components/LanguageSwitcher'

import styles from './unauthenticatedLayoutTemplate.module.less'

export enum ImageTypeEnum {
  default,
  accountType,
  studentBirthday,
  studentInfo,
  studentInterests,
  companyInfo,
  companyContact,
}
type OnboardingImageContextType = {
  image: ImageTypeEnum
  setImage: (v: ImageTypeEnum) => void
}
export const OnboardingImageContext = createContext<OnboardingImageContextType>({
  image: ImageTypeEnum.default,
  setImage: () => {},
})

export const UnauthenticatedLayoutTemplate = () => {
  const breakpoint = useBreakpoint()
  const { i18n } = useTranslation()

  const [image, setImage] = useState<ImageTypeEnum>(ImageTypeEnum.default)

  const rootClasses = clsx(styles.root, {
    [styles.with_logo]: breakpoint.lg,
  })

  const rootWrapperClasses = clsx({
    [styles.xs]: breakpoint.xs,
    [styles.sm]: breakpoint.sm,
  })

  return (
    <div className={rootClasses}>
      <div className={rootWrapperClasses}>
        <Row>
          <Col flex={!breakpoint.lg ? 'auto' : '550px'}>
            <div className={styles.header}>
              <Link to='/wp'>
                <Image src={Logo} preview={false} />
              </Link>
              <LanguageSwitcher
                loading={false}
                defaultLanguage={i18n.language as FlagType}
                onLanguageChange={(lang) => {
                  i18n.changeLanguage(lang)
                }}
              />
            </div>
            <Col span={24} className={styles.layoutContent}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <OnboardingImageContext.Provider
                    value={{
                      image,
                      setImage,
                    }}
                  >
                    <Outlet />
                  </OnboardingImageContext.Provider>
                </Col>
              </Row>
            </Col>
          </Col>
          {breakpoint.lg && (
            <>
              <Col flex='80px' />
              <Col flex='auto'>
                <div
                  className={clsx({
                    [styles.studentBirthdayImage]: image === ImageTypeEnum.studentBirthday,
                    [styles.studentInfoImage]: image === ImageTypeEnum.studentInfo,
                    [styles.studentInterestsImage]: image === ImageTypeEnum.studentInterests,
                    [styles.defaultImage]: image === ImageTypeEnum.default,
                    [styles.accountTypeImage]: image === ImageTypeEnum.accountType,
                    [styles.companyInfoImage]: image === ImageTypeEnum.companyInfo,
                    [styles.companyContactImage]: image === ImageTypeEnum.companyContact,
                  })}
                />
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  )
}
