import { FC } from 'react'
import { Image } from 'antd'
import clsx from 'classnames'

import NlFlag from '@/assets/images/nl_flag.svg'
import UsFlag from '@/assets/images/us_flag.svg'

import styles from './styles.module.less'

export type FlagType = 'nl' | 'en'

interface FlagProps {
  type: FlagType
  active: boolean
  loading: boolean
  focusable?: boolean
  onClick: () => void
  last?: boolean
}

const flags: Record<FlagType, string> = {
  en: UsFlag,
  nl: NlFlag,
}

export const Flag: FC<FlagProps & Pick<Parameters<typeof Image>[0], 'onClick'>> = ({
  type,
  active,
  focusable,
  last,
  loading,
  onClick,
  ...props
}: FlagProps) => {
  const classes = clsx(styles.flag, {
    [styles.active]: active,
    [styles.focusable]: focusable,
    [styles.last]: last,
  })

  return (
    <button onClick={!active ? onClick : undefined} className={styles.button}>
      {!active && loading && (
        <div className={styles.loader}>
          <span />
        </div>
      )}

      <div className={classes}>
        <Image src={flags[type]} width={30} height={30} preview={false} {...props} />
      </div>
    </button>
  )
}
