import { StudentProjectAssignmentPage } from '@/pages/StudentPages'

export default {
  path: 'projectAssignment',
  children: [
    {
      path: ':id',
      element: <StudentProjectAssignmentPage />,
    },
  ],
}
