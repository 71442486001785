import { useLocation, useNavigate } from "react-router-dom"

import { StudentProjectCreatedSuccessfullyScreen } from "@/screens/StudentScreens/StudentProjectCreatedSuccessfullyScreen";

export const StudentProjectCreatedSuccessfullyPage = () => {
    const location = useLocation();

    const navigate = useNavigate();

    const onAgree = () => {
        navigate(`/marketplace/${location.state.publicId}/${location.state.id}`)
    }

    return <StudentProjectCreatedSuccessfullyScreen onAgree={onAgree} />

}