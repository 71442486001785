import loadable from '@loadable/component'

import { LoadingScreen } from '@/screens/CommonScreens'

export const AssignmentChatRedirectPage = loadable(() =>
  import('@/pages/StudentPages/AssignmentChatRedirectPage').then((module) => ({
    default: module.AssignmentChatRedirectPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const AssignmentPage = loadable(() =>
  import('@/pages/StudentPages/AssignmentPage').then((module) => ({
    default: module.AssignmentPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyChatRedirectPage = loadable(() =>
  import('@/pages/StudentPages/CompanyChatRedirectPage').then((module) => ({
    default: module.CompanyChatRedirectPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const JobsAdministrationPage = loadable(() =>
  import('@/pages/StudentPages/JobsAdministrationPage').then((module) => ({
    default: module.JobsAdministrationPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const MyDocumentsPage = loadable(() =>
  import('@/pages/StudentPages/MyDocumentsPage').then((module) => ({
    default: module.MyDocumentsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const MyJobsPage = loadable(() =>
  import('@/pages/StudentPages/MyJobsPage').then((module) => ({
    default: module.MyJobsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentApplyToJobPage = loadable(() =>
  import('@/pages/StudentPages/StudentApplyToJobPage').then((module) => ({
    default: module.StudentApplyToJobPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentApplyToVacancyPage = loadable(() =>
  import('@/pages/StudentPages/StudentApplyToVacancyPage').then((module) => ({
    default: module.StudentApplyToVacancyPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentChatPage = loadable(() =>
  import('@/pages/StudentPages/StudentChatPage').then((module) => ({
    default: module.StudentChatPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentDashboardPage = loadable(() =>
  import('@/pages/StudentPages/StudentDashboardPage').then((module) => ({
    default: module.StudentDashboardPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentJobPage = loadable(() =>
  import('@/pages/StudentPages/StudentJobPage').then((module) => ({
    default: module.StudentJobPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentMarketPlacePage = loadable(() =>
  import('@/pages/StudentPages/StudentMarketPlacePage').then((module) => ({
    default: module.StudentMarketPlacePage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentProfilePage = loadable(() =>
  import('@/pages/StudentPages/StudentProfilePage').then((module) => ({
    default: module.StudentProfilePage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentProfileSuccessPage = loadable(() =>
  import('@/pages/StudentPages/StudentProfileSuccessPage').then((module) => ({
    default: module.StudentProfileSuccessPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentProjectAssignmentPage = loadable(() =>
  import('@/pages/StudentPages/StudentProjectAssignmentPage').then((module) => ({
    default: module.StudentProjectAssignmentPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentProjectCreatePage = loadable(() =>
  import('@/pages/StudentPages/StudentProjectCreatePage').then((module) => ({
    default: module.StudentProjectCreatePage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentProjectCreateSuccessPage = loadable(() =>
  import('@/pages/StudentPages/StudentProjectCreateSuccessPage').then((module) => ({
    default: module.StudentProjectCreateSuccessPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentProjectCreatedSuccessfullyPage = loadable(() =>
  import('@/pages/StudentPages/StudentProjectCreatedSuccessfullyPage').then((module) => ({
    default: module.StudentProjectCreatedSuccessfullyPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentProjectEditPage = loadable(() =>
  import('@/pages/StudentPages/StudentProjectEditPage').then((module) => ({
    default: module.StudentProjectEditPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentProjectPage = loadable(() =>
  import('@/pages/StudentPages/StudentProjectPage').then((module) => ({
    default: module.StudentProjectPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentReviewAssignmentPage = loadable(() =>
  import('@/pages/StudentPages/StudentReviewAssignmentPage').then((module) => ({
    default: module.StudentReviewAssignmentPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentReviewJobPage = loadable(() =>
  import('@/pages/StudentPages/StudentReviewJobPage').then((module) => ({
    default: module.StudentReviewJobPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentReviewProjectAssignmentPage = loadable(() =>
  import('@/pages/StudentPages/StudentReviewProjectAssignmentPage').then((module) => ({
    default: module.StudentReviewProjectAssignmentPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentReviewsPage = loadable(() =>
  import('@/pages/StudentPages/StudentReviewsPage').then((module) => ({
    default: module.StudentReviewsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentSearchJobsPage = loadable(() =>
  import('@/pages/StudentPages/StudentSearchJobsPage').then((module) => ({
    default: module.StudentSearchJobsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const StudentSuccessfullyAppliedToVacancyPage = loadable(() =>
  import('@/pages/StudentPages/StudentSuccessfullyAppliedToVacancyPage').then((module) => ({
    default: module.StudentSuccessfullyAppliedToVacancyPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const TalentPrivateProjectAssignmentCreatePage = loadable(() =>
  import('@/pages/StudentPages/TalentPrivateProjectAssignmentCreatePage').then((module) => ({
    default: module.TalentPrivateProjectAssignmentCreatePage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const TalentPrivateProjectAssignmentUpdatePage = loadable(() =>
  import('@/pages/StudentPages/TalentPrivateProjectAssignmentUpdatePage').then((module) => ({
    default: module.TalentPrivateProjectAssignmentUpdatePage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const VacancyPage = loadable(() =>
  import('@/pages/StudentPages/VacancyPage').then((module) => ({
    default: module.VacancyPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          