import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, notification, Row } from 'antd'
import clsx from 'classnames'

import { useSetLocaleMutation } from '@/apollo/generated/graphql'
import { FlagType } from '@/components/Flag'
import { LanguageSwitcher } from '@/components/LanguageSwitcher'
import { Link } from '@/components/Link'
import { useLogout } from '@/providers/AuthenticationProvider/hooks/useLogout'

import styles from './styles.module.less'

interface UserActionsDropDownProps {
  className?: string
}

export const UserActionsDropDown: FC<UserActionsDropDownProps> = ({ className }) => {
  const { t, i18n } = useTranslation()
  const logout = useLogout()

  const [setLocale, { loading }] = useSetLocaleMutation()

  return (
    <div className={clsx(className, styles.container)}>
      <Row gutter={[16, 26]}>
        <Col xs={24}>
          <LanguageSwitcher
            loading={loading}
            defaultLanguage={i18n.language as FlagType}
            onLanguageChange={(lang) => {
              setLocale({
                variables: {
                  app_language: lang,
                  docs_language: lang,
                },
              })
                .then(() => i18n.changeLanguage(lang))
                .catch((e) =>
                  notification.error({
                    message: e,
                  }),
                )
            }}
          />
        </Col>
        <Col xs={24}>
          <Link to='/profile' text={t('navigation.profile')} icon='UserSvg' />
        </Col>
        <Col xs={24}>
          <Link
            to='/'
            text={t('auth.logout')}
            icon='SignOutSvg'
            onClick={logout}
            data-cy='cy-auth.logout'
          />
        </Col>
      </Row>
    </div>
  )
}
