export function underAgeValidate(birthday: Date) {
  const current = birthday.getTime()
  const now1 = new Date()
  const now2 = new Date()

  now1.setFullYear(now1.getFullYear() - 16)
  now2.setFullYear(now2.getFullYear() - 18)

  return {
    over16: now1.getTime() > current,
    over18: now2.getTime() > current,
  }
}

export const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const formatCurrency = (number: number | bigint | string) => {
  const value = typeof number === 'string' ? parseFloat(number) : number

  return Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  })
    .format(value)
    .replace(/\s+/g, ' ')
}

export const formatPercentage = (number: number | bigint | string) => {
  const value = typeof number === 'string' ? parseFloat(number) : number

  return Intl.NumberFormat('nl-NL', {
    style: 'percent',
  })
    .format((value as number) / 100)
    .slice(0, -1)
    .replace(/^/, '% ')
}

export const formatLanguageName = (name?: string | null, locale = 'en') => {
  if (name === 'any' || name === 'Any') {
    return 'Any'
  }

  return (
    new Intl.DisplayNames([locale], {
      type: 'language',
    }).of(name ?? 'Unknown') ?? 'Unknown'
  )
}

export const stripHtml = (html: string) => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

export const dataURLtoImage = (dataUrl: string, filename = 'image.jpeg') =>
  fetch(dataUrl)
    .then((res) => res.blob())
    .then((myBlob) => new File([myBlob], filename, { type: myBlob.type }))

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',')
  const mime = arr[0]?.match(/:(.*?);/)?.[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const getFileTypeFromBase64 = (base64String: string): string => {
  // Regular expression for data URI scheme
  const dataURIPattern = /^data:([a-zA-Z]+\/[a-zA-Z]+);base64,(.*)$/

  // Extract the MIME type and encoded data
  const match = base64String.match(dataURIPattern)
  if (!match) {
    throw new Error('Invalid base64 string')
  }
  const mimeType = match[1]

  // Get the file extension from the MIME type
  return mimeType.split('/')[1]
}

export function safeParseJSON (string: string): string {
  try { return JSON.parse(string) }
  catch { return string }
}

export const tableDateFormat = 'DD MMMM YYYY / hh:mm'
