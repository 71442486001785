import loadable from '@loadable/component'

import { LoadingScreen } from '@/screens/CommonScreens'

export const AdminCompaniesPage = loadable(() =>
  import('@/pages/AdminPages/AdminCompaniesPage').then((module) => ({
    default: module.AdminCompaniesPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const AdminCompanyPage = loadable(() =>
  import('@/pages/AdminPages/AdminCompanyPage').then((module) => ({
    default: module.AdminCompanyPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const AdminDashboardPage = loadable(() =>
  import('@/pages/AdminPages/AdminDashboardPage').then((module) => ({
    default: module.AdminDashboardPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const AdminDocumentsPage = loadable(() =>
  import('@/pages/AdminPages/AdminDocumentsPage').then((module) => ({
    default: module.AdminDocumentsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const AdminSettingsPage = loadable(() =>
  import('@/pages/AdminPages/AdminSettingsPage').then((module) => ({
    default: module.AdminSettingsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const AdminStudentDetailsPage = loadable(() =>
  import('@/pages/AdminPages/AdminStudentDetailsPage').then((module) => ({
    default: module.AdminStudentDetailsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const AdminStudentsPage = loadable(() =>
  import('@/pages/AdminPages/AdminStudentsPage').then((module) => ({
    default: module.AdminStudentsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const AssignmentPage = loadable(() =>
  import('@/pages/AdminPages/AssignmentPage').then((module) => ({
    default: module.AssignmentPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const JobsAdministrationPage = loadable(() =>
  import('@/pages/AdminPages/JobsAdministrationPage').then((module) => ({
    default: module.JobsAdministrationPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const VacancyPage = loadable(() =>
  import('@/pages/AdminPages/VacancyPage').then((module) => ({
    default: module.VacancyPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          