import loadable from '@loadable/component'

import { LoadingScreen } from '@/screens/CommonScreens'

export const AssignmentChatRedirectPage = loadable(() =>
  import('@/pages/CompanyPages/AssignmentChatRedirectPage').then((module) => ({
    default: module.AssignmentChatRedirectPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const AssignmentPage = loadable(() =>
  import('@/pages/CompanyPages/AssignmentPage').then((module) => ({
    default: module.AssignmentPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyChatPage = loadable(() =>
  import('@/pages/CompanyPages/CompanyChatPage').then((module) => ({
    default: module.CompanyChatPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyDashboardPage = loadable(() =>
  import('@/pages/CompanyPages/CompanyDashboardPage').then((module) => ({
    default: module.CompanyDashboardPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyJobPage = loadable(() =>
  import('@/pages/CompanyPages/CompanyJobPage').then((module) => ({
    default: module.CompanyJobPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyJobsPage = loadable(() =>
  import('@/pages/CompanyPages/CompanyJobsPage').then((module) => ({
    default: module.CompanyJobsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyMarketPlacePage = loadable(() =>
  import('@/pages/CompanyPages/CompanyMarketPlacePage').then((module) => ({
    default: module.CompanyMarketPlacePage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyProfilePage = loadable(() =>
  import('@/pages/CompanyPages/CompanyProfilePage').then((module) => ({
    default: module.CompanyProfilePage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyProjectApplyPage = loadable(() =>
  import('@/pages/CompanyPages/CompanyProjectApplyPage').then((module) => ({
    default: module.CompanyProjectApplyPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyProjectAssignmentPage = loadable(() =>
  import('@/pages/CompanyPages/CompanyProjectAssignmentPage').then((module) => ({
    default: module.CompanyProjectAssignmentPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyReviewAssignmentPage = loadable(() =>
  import('@/pages/CompanyPages/CompanyReviewAssignmentPage').then((module) => ({
    default: module.CompanyReviewAssignmentPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyReviewJobPage = loadable(() =>
  import('@/pages/CompanyPages/CompanyReviewJobPage').then((module) => ({
    default: module.CompanyReviewJobPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyReviewProjectAssignmentPage = loadable(() =>
  import('@/pages/CompanyPages/CompanyReviewProjectAssignmentPage').then((module) => ({
    default: module.CompanyReviewProjectAssignmentPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CompanyReviewsPage = loadable(() =>
  import('@/pages/CompanyPages/CompanyReviewsPage').then((module) => ({
    default: module.CompanyReviewsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CreateJobPage = loadable(() =>
  import('@/pages/CompanyPages/CreateJobPage').then((module) => ({
    default: module.CreateJobPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const CreateVacancySuccessPage = loadable(() =>
  import('@/pages/CompanyPages/CreateVacancySuccessPage').then((module) => ({
    default: module.CreateVacancySuccessPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const JobsAdministrationPage = loadable(() =>
  import('@/pages/CompanyPages/JobsAdministrationPage').then((module) => ({
    default: module.JobsAdministrationPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const MyDocumentsPage = loadable(() =>
  import('@/pages/CompanyPages/MyDocumentsPage').then((module) => ({
    default: module.MyDocumentsPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const TalentChatRedirectPage = loadable(() =>
  import('@/pages/CompanyPages/TalentChatRedirectPage').then((module) => ({
    default: module.TalentChatRedirectPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const VacancyCreatePage = loadable(() =>
  import('@/pages/CompanyPages/VacancyCreatePage').then((module) => ({
    default: module.VacancyCreatePage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const VacancyEditPage = loadable(() =>
  import('@/pages/CompanyPages/VacancyEditPage').then((module) => ({
    default: module.VacancyEditPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const VacancyPage = loadable(() =>
  import('@/pages/CompanyPages/VacancyPage').then((module) => ({
    default: module.VacancyPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const VacancyReopenPage = loadable(() =>
  import('@/pages/CompanyPages/VacancyReopenPage').then((module) => ({
    default: module.VacancyReopenPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const VacancyReopenSuccessPage = loadable(() =>
  import('@/pages/CompanyPages/VacancyReopenSuccessPage').then((module) => ({
    default: module.VacancyReopenSuccessPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          