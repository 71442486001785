import React from 'react'

import { ErrorBoundary } from '@/containers/ErrorBoundary'
import { AuthenticatedLayout, EmptyLayout } from '@/layouts'
import {
  AdminCompaniesPage,
  AdminCompanyPage,
  AdminDashboardPage,
  AdminDocumentsPage,
  AdminSettingsPage,
  AdminStudentDetailsPage,
  AdminStudentsPage,
  AssignmentPage,
  JobsAdministrationPage,
  VacancyPage,
} from '@/pages/AdminPages'
import { VacancyEditPage } from '@/pages/CompanyPages'
import AuthRedirectRoutes from '@/router/AuthRedirectRoutes'

export const AdminRoutes = {
  path: '/',
  element: <ErrorBoundary />,
  children: [
    {
      element: <AuthenticatedLayout />,
      children: [
        {
          index: true,
          element: <AdminDashboardPage />,
        },
        {
          path: 'students',
          children: [
            {
              index: true,
              element: <AdminStudentsPage />,
            },
            {
              path: ':id',
              element: <AdminStudentDetailsPage />,
            },
          ],
        },
        {
          path: 'companies',
          children: [
            {
              index: true,
              element: <AdminCompaniesPage />,
            },
            {
              path: ':id',
              element: <AdminCompanyPage />,
            },
          ],
        },
        {
          path: 'documents',
          element: <AdminDocumentsPage />,
        },
        {
          path: 'settings',
          element: <AdminSettingsPage />,
        },
        {
          path: 'vacancies',
          children: [
            {
              index: true,
              element: <JobsAdministrationPage />,
            },
            {
              path: ':id',
              element: <VacancyPage />,
            },
          ],
        },
        {
          path: 'assignments',
          children: [
            {
              path: ':id',
              element: <AssignmentPage />,
            },
          ],
        },
        ...AuthRedirectRoutes,
      ],
    },
    {
      element: <EmptyLayout />,
      children: [
        {
          path: 'vacancies/:id/edit',
          element: <VacancyEditPage />,
        },
      ],
    },
  ],
}
