import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
// eslint-disable-next-line import/no-extraneous-dependencies
import * as IBAN from 'iban'

import { regexRules } from '@/forms/helpers'

export type Rule = Exclude<
  Parameters<(typeof Form)['Item']>[0]['rules'],
  undefined
> extends (infer U)[]
  ? U
  : never

export type Validations = {
  required: Rule
  lastName: Rule
  atLeastOneCapitalLetterRule: Rule
  atLeastOneDigitRule: Rule
  atLeastOneSpecialCharRule: Rule
  email: Rule
  linkedin: Rule[]
  cocNumber: Rule[]
  requiredWithWhitespace: Rule[]
  phone: Rule[]
  phoneOptional: Rule[]
  confirmEmail: (field: string) => Rule
  firstName: () => Rule
  vatNumber: Rule[]
  confirmPassword: Rule
  wysiwygIsRequired: Rule
  iban: (field: string) => Rule
  bic: Rule
  maxHourlyAmount: Rule
  maxFixedAmount: Rule
  positiveValue: Rule
  workingHours: Rule
  mobileNumber: Rule
  max: (max: number) => Rule
  min: (min: number) => Rule
  number: Rule
  commaDelimited: Rule
}

export const useValidationRules = (): Validations => {
  const { t } = useTranslation()
  return {
    required: { required: true, message: t('errors.required') ?? undefined },
    commaDelimited: {
      pattern: regexRules.commaDelimited,
      message: t('errors.commaDelimited') ?? undefined,
    },
    mobileNumber: {
      pattern: regexRules.mobileNumber,
      message: t('errors.mobileNumber') ?? undefined,
    },
    workingHours: {
      pattern: regexRules.workingHours,
      message: t('errors.workingHours') ?? undefined,
    },
    maxHourlyAmount: {
      pattern: regexRules.maxHourlyAmount,
      message: t('errors.maxHourlyAmount') ?? undefined,
    },
    maxFixedAmount: {
      pattern: regexRules.maxFixedAmount,
      message: t('errors.maxFixedAmount') ?? undefined,
    },
    positiveValue: {
      pattern: regexRules.positiveValue,
      message: t('errors.positiveValue') ?? undefined,
    },
    number: {
      validator(_, value) {
        const number = Number(value)

        if (Number.isNaN(number)) {
          return Promise.reject(new Error('it`s not a number'))
        }

        return Promise.resolve()
      },
    },
    max(max: number) {
      return {
        validator(_, value) {
          const number = value

          if (Number.isNaN(number)) {
            return Promise.reject(new Error('it`s not a number'))
          }

          if (number > max) {
            return Promise.reject(new Error(t('errors.max', { max }) || ''))
          }

          return Promise.resolve()
        },
      }
    },
    min(min: number) {
      return {
        validator(_, value) {
          const number = Number(value)

          if (Number.isNaN(number)) {
            return Promise.reject(new Error('it`s not a number'))
          }

          if (number < min) {
            return Promise.reject(new Error(t('errors.min', { min }) || ''))
          }

          return Promise.resolve()
        },
      }
    },
    firstName() {
      return {
        validator(_, value) {
          if (regexRules.isFirstUppercaseLetter.test(value.replace(/- /g, ''))) {
            return Promise.resolve()
          }

          return Promise.reject(new Error(t('errors.firstName') || ''))
        },
      }
    },
    lastName: {
      pattern: regexRules.lastName,
      message: t('errors.lastName') ?? undefined,
    },
    atLeastOneCapitalLetterRule: {
      pattern: regexRules.atLeastOneCapitalLetter,
      message: t('errors.at_least_one_capital_letter') ?? undefined,
    },
    atLeastOneDigitRule: {
      pattern: regexRules.hasNumber,
      message: t('errors.at_least_one_digit') ?? undefined,
    },
    atLeastOneSpecialCharRule: {
      pattern: regexRules.specialCharacter,
      message: t('errors.at_least_one_special_char') ?? undefined,
    },
    email: {
      type: 'email',
      message: t('errors.email') ?? undefined,
    },
    requiredWithWhitespace: [
      { required: true, message: t('errors.required') ?? undefined },
      { whitespace: true, message: t('errors.required') ?? undefined },
    ],
    linkedin: [
      {
        pattern: regexRules.linkedin,
        message: t('errors.invalid_format') ?? undefined,
      },
      { whitespace: true },
    ],
    cocNumber: [
      {
        pattern: regexRules.coc,
        message: t('errors.invalid_format') ?? undefined,
      },
      { whitespace: true },
    ],
    vatNumber: [
      {
        pattern: regexRules.vat,
        message: t('errors.invalid_format') ?? undefined,
      },
    ],
    iban() {
      return {
        validator(_, value) {
          if (IBAN.isValid(value)) {
            return Promise.resolve()
          }

          return Promise.reject(new Error(t('errors.invalid_format') || ''))
        },
      }
    },
    bic: {
      pattern: regexRules.bic,
      message: t('errors.invalid_format') ?? undefined,
    },
    phone: [
      { required: true, message: t('errors.phone') ?? undefined },
      {
        pattern: regexRules.phone,
        message: t('errors.invalid_phone') ?? undefined,
      },
    ],
    phoneOptional: [
      {
        pattern: regexRules.phone,
        message: t('errors.invalid_phone') ?? undefined,
      },
    ],
    confirmEmail:
      (field) =>
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue(field) === value) {
            return Promise.resolve()
          }
          return Promise.reject(new Error(t('errors.email_same') ?? undefined))
        },
      }),

    confirmPassword({ getFieldValue }) {
      return {
        validator(_, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve()
          }
          return Promise.reject(new Error(t('auth.resetPassword.notMatch') || undefined))
        },
      }
    },
    wysiwygIsRequired() {
      return {
        validator(_, value) {
          if (!value) {
            return Promise.reject(new Error(t('errors.required') || ''))
          }

          const replacedValue = value.replace(regexRules.htmlTagPattern, '').trim().length

          if (replacedValue > 0 && replacedValue <= 2000) {
            return Promise.resolve()
          }

          const errorMessage =
            replacedValue >= 2000
              ? t('errors.maxAmount', { remainingCharacters: replacedValue })
              : t('errors.required')

          return Promise.reject(new Error(errorMessage))
        },
      }
    },
  }
}
