import { FC, useCallback, useEffect, useState } from 'react'
import { Space } from 'antd'

import { Flag, FlagType } from '@/components/Flag'

type LanguageSwitcherProps = {
  loading: boolean
  defaultLanguage: FlagType
  onLanguageChange?: (flag: FlagType) => void
}

type FlagConfig = {
  type: FlagType
}

const flags: FlagConfig[] = [
  {
    type: 'en',
  },
  {
    type: 'nl',
  },
]

export const LanguageSwitcher: FC<LanguageSwitcherProps> = ({
  defaultLanguage,
  onLanguageChange,
  loading,
}) => {
  const [language, setLanguage] = useState<FlagType>(defaultLanguage)

  const onClickHandler = useCallback(
    (selectedLanguage: FlagType) => () => {
      onLanguageChange?.(selectedLanguage)
      localStorage.setItem('lang', selectedLanguage)
    },
    [],
  )

  useEffect(() => {
    setLanguage(defaultLanguage)
  }, [defaultLanguage])

  return (
    <Space direction='horizontal' size={8}>
      {flags.map(({ type }) => (
        <Flag
          key={type}
          loading={loading}
          type={type}
          active={language === type}
          focusable={language !== type}
          onClick={onClickHandler(type)}
        />
      ))}
    </Space>
  )
}
