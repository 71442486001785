import { ErrorBoundary } from '@/containers/ErrorBoundary'
import { AuthenticatedLayout, EmptyLayout } from '@/layouts'
import { NotFoundPage, ToDoPage } from '@/pages/CommonPages'
import {
  AssignmentChatRedirectPage,
  AssignmentPage,
  CompanyChatRedirectPage,
  JobsAdministrationPage,
  MyDocumentsPage,
  StudentApplyToVacancyPage,
  StudentChatPage,
  StudentDashboardPage,
  StudentMarketPlacePage,
  StudentProfilePage,
  StudentProjectPage,
  StudentReviewAssignmentPage,
  StudentReviewJobPage,
  StudentReviewProjectAssignmentPage,
  StudentReviewsPage,
  VacancyPage,
} from '@/pages/StudentPages'
import { StudentProfileSuccessPage } from '@/pages/StudentPages/StudentProfileSuccessPage'
import { StudentSuccessfullyAppliedToVacancyPage } from '@/pages/StudentPages/StudentSuccessfullyAppliedToVacancyPage'
import AuthRedirectRoutes from '@/router/AuthRedirectRoutes'
import ProjectAssignmentRoutes from '@/router/StudentRoutes/ProjectAssignmentRoutes'
import ProjectsPage from '@/router/StudentRoutes/ProjectsRoutes'

export const StudentRoutes = {
  path: '/',
  element: <ErrorBoundary />,
  children: [
    {
      element: <AuthenticatedLayout />,
      children: [
        {
          path: 'chat',
          element: <StudentChatPage />,
        },
      ],
    },
    {
      element: <AuthenticatedLayout />,
      children: [
        {
          path: 'chat/:id',
          element: <StudentChatPage />,
        },
      ],
    },
    {
      element: <AuthenticatedLayout />,
      children: [
        {
          index: true,
          element: <StudentDashboardPage />,
        },
        {
          path: 'profile',
          element: <StudentProfilePage />,
        },
        {
          path: 'profile/success/:profileId',
          element: <StudentProfileSuccessPage />,
        },
        {
          path: 'vacancies',
          children: [
            // {
            //   index: true,
            //   element: <MyJobsPage />,
            // },
            {
              path: ':id',
              element: <VacancyPage />,
            },
          ],
        },
        {
          path: 'projects',
          children: [
            {
              index: true,
              element: <StudentMarketPlacePage />,
            },
          ],
        },
        {
          path: 'projects/:id',
          element: <StudentProjectPage />,
        },
        {
          path: 'assignments',
          children: [
            {
              index: true,
              element: <JobsAdministrationPage />,
            },
            {
              path: ':id',
              element: <AssignmentPage />,
            },
          ],
        },
        {
          path: 'documents',
          element: <MyDocumentsPage />,
        },
        {
          path: 'reviews',
          element: <StudentReviewsPage />,
        },
        {
          path: 'to-do',
          element: <ToDoPage />,
        },
        ProjectAssignmentRoutes,
        ...AuthRedirectRoutes,
      ],
    },
    {
      element: <EmptyLayout />,
      children: [
        {
          path: '404',
          element: <NotFoundPage />,
        },
        {
          path: 'review/:jobID',
          element: <StudentReviewJobPage />,
        },
        {
          path: 'projectAssignment/:id/review',
          element: <StudentReviewProjectAssignmentPage />,
        },
        {
          path: 'assignments/:jobID/review',
          element: <StudentReviewAssignmentPage />,
        },
        {
          path: 'assignments/:id/chat',
          element: <AssignmentChatRedirectPage />,
        },
        {
          path: 'companies/:id/chat',
          element: <CompanyChatRedirectPage />,
        },
        {
          path: 'jobs',
          children: [
            {
              path: ':id/apply',
              element: <StudentApplyToVacancyPage />,
            },
            {
              path: ':id/apply/success',
              element: <StudentSuccessfullyAppliedToVacancyPage />,
            },
            // {
            //   path: 'search',
            //   element: <StudentSearchJobsPage />,
            // },
          ],
        },
        ProjectsPage,
      ],
    },
  ],
}
