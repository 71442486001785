import { initReactI18next } from 'react-i18next'
import { en as SearchModuleEn, nl as SearchModuleNL } from '@kickjobs/search-marketplace-module'
import i18n from 'i18next'

import { en, nl } from './resources'

i18n.use(initReactI18next).init({
  resources: { en: { ...en, ...SearchModuleEn }, nl: { ...nl, ...SearchModuleNL } }, // if you're using a language detector, do not define the lng option
  fallbackLng: localStorage.getItem('lang') ?? 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
